import React from 'react'
import Question from '../../components/sections/Question'

function Kompany_question() {
  return (
    <div>
        <Question/>
    </div>
  )
}

export default Kompany_question