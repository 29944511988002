import React from 'react'
import ContactInfo from '../../components/sections/ContactInfo'

function Contact() {
  return (
    <div>
        <ContactInfo/>
    </div>
  )
}

export default Contact