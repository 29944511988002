import userimg from "../../assets/images/userimg.png";

export const mijozlar = [
  {
    img: userimg,
    text: "Обратился за новыми каркасными щётками, в итоге уехал и со щётками и с крутыми меховушками! Самый первый бокс на рынке на Нагорном, и с адекватными ценами! Обращаться к Андрею, очень толковый и отзывчивый продавец!",
    fname: "Сергей ",
  },
  {
    img: userimg,
    text: 'Lorem abhvakjhbyu akbvuaygvakbasdkjgay jahhgiauygiukajwh vjg,mvsand ',
    fname: "Jack",
  },
  {
    img: userimg,
    text: "Обр с крутыми меховушками! Самый первый бокс на рынке на Нагорном, и с адекватными ценами! Обращаться к Андрею, очень толковый и отзывчивый продавец!",
    fname: "John",
  },
  {
    img: userimg,
    text: "Обр щётками и с крутыми меховушками! Самый первый бокс на рынке на Нагорном, и с адекватными ценами! Обращаться к Андрею, очень толковый и отзывчивый продавец!",
    fname: "Doe",
  },
  {
    img: userimg,
    text: "Обратилсящётками, в итоге уехал и со щётками и с крутыми меховушками! Самый первый бокс на рынке на Нагорном, и с адекватными ценами! Обращаться к Андрею, очень толковый и отзывчивый продавец!",
    fname: "Misha",
  },
];
