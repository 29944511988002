import nw from "../../assets/images/nw.png";
import akk from "../../assets/images/akk.png";
import dis from "../../assets/images/dis.png";

export const newsArray = [
  {
    id: 1,
    img: nw,
    data: "24 февраля 2020 года",
    title: "МЫ ОБНОВИЛИСЬ. ТЕПЕРЬ У НАС НОВЫЙ ЛОГОТИП И ОБНОВЛЕННЫЙ САЙТ.",
    text: "Мы рады ононсировать наш обновленный внешний мир. Мы не стоим на месте, постоянно развиваемся и улучшаем качество наших услуг, а также следим и за своим внешним видом.",
  },
  {
    id: 2,
    img: nw,
    data: "10 февраля 2020 года",
    title: "ОБНОВЛЕНИЕ ЛИНЕЙКИ АККУМУЛЯТОРОВ VARTRA ",
    text: "Мы рады ононсировать наш обновленный внешний мир. Мы не стоим на месте, постоянно развиваемся и улучшаем качество наших услуг, а также следим и за своим внешним видом.",
  },
  {
    id: 3,
    img: akk,
    data: "5 февраля 2020 года",
    title: "БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ СПЕЦИАЛИСТА ТЕПЕРЬ В РЕЖИМЕ ONLINE",
    text: "Мы рады ононсировать наш обновленный внешний мир. Мы не стоим на месте, постоянно развиваемся и улучшаем качество наших услуг, а также следим и за своим внешним видом.",
  },
  {
    id: 4,
    img: dis,
    data: "24 февраля 2020 года",
    title: "МЫ ОБНОВИЛИСЬ. ТЕПЕРЬ У НАС НОВЫЙ ЛОГОТИП И ОБНОВЛЕННЫЙ САЙТ.",
    text: "Мы рады ононсировать наш обновленный внешний мир. Мы не стоим на месте, постоянно развиваемся и улучшаем качество наших услуг, а также следим и за своим внешним видом.",
  },
  {
    id: 5,
    img: nw,
    data: "24 февраля 2020 года",
    title: "МЫ ОБНОВИЛИСЬ. ТЕПЕРЬ У НАС НОВЫЙ ЛОГОТИП И ОБНОВЛЕННЫЙ САЙТ.",
    text: "Мы рады ононсировать наш обновленный внешний мир. Мы не стоим на месте, постоянно развиваемся и улучшаем качество наших услуг, а также следим и за своим внешним видом.",
  },
  {
    id: 6,
    img: nw,
    data: "24 февраля 2020 года",
    title: "МЫ ОБНОВИЛИСЬ. ТЕПЕРЬ У НАС НОВЫЙ ЛОГОТИП И ОБНОВЛЕННЫЙ САЙТ.",
    text: "Мы рады ононсировать наш обновленный внешний мир. Мы не стоим на месте, постоянно развиваемся и улучшаем качество наших услуг, а также следим и за своим внешним видом.",
  },
  {
    id: 7,
    img: nw,
    data: "24 февраля 2020 года",
    title: "МЫ ОБНОВИЛИСЬ. ТЕПЕРЬ У НАС НОВЫЙ ЛОГОТИП И ОБНОВЛЕННЫЙ САЙТ.",
    text: "Мы рады ононсировать наш обновленный внешний мир. Мы не стоим на месте, постоянно развиваемся и улучшаем качество наших услуг, а также следим и за своим внешним видом.",
  },
];
