import srtfkt1 from "../../assets/images/srtfkt1.png";
import srtfkt2 from "../../assets/images/srtfkt2.png";
import srtfkt3 from "../../assets/images/srtfkt3.png";
import srtfkt4 from "../../assets/images/srtfkt4.png";
import srtfkt5 from "../../assets/images/srtfkt5.png";
import srtfkt6 from "../../assets/images/srtfkt6.png";
import srtfkt7 from "../../assets/images/srtfkt7.png";


export const Hmk = [
    {
        img:srtfkt1,
        cname:'Компания «ПартКом»'
    },
    {
        img:srtfkt2,
        cname:'Компания «АРМТЕК»'
    },
    {
        img:srtfkt3,
        cname:'Компания «Бином»'
    },
    {
        img:srtfkt4,
        cname:'Компания «Маслон»'
    },
    {
        img:srtfkt5,
        cname:'ООО «Аккумуляторный Мир»'
    },
    {
        img:srtfkt6,
        cname:'Компания «ЮНИКС» г. Киров'
    },
    {
        img:srtfkt7,
        cname:'Компания «Фаворит»'
    },
]