import React from "react";
import style from "./index.module.scss";
import { Notbutton } from "../../components/Button";
export function NotFound() {
  return (
    <>
      <div className={style.notfont_wrapper}>
        <div className={style.notfont_wrapper_svg}>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="268"
              height="106"
              viewBox="0 0 268 106"
              fill="none"
            >
              <path
                d="M51.0854 100.245V102.245H53.0854H67.6892H69.6892V100.245V77.5427H82.4284H84.4284V75.5427V62.854V60.854H82.4284H69.6892V5.75482V3.75482H67.6892H53.0854H52.1532L51.5538 4.46862L2.46844 62.9177L2 63.4754V64.2039V75.5427V77.5427H4H51.0854V100.245ZM232.657 100.245V102.245H234.657H249.261H251.261V100.245V77.5427H264H266V75.5427V62.854V60.854H264H251.261V5.75482V3.75482H249.261H234.657H233.725L233.125 4.46862L184.04 62.9177L183.572 63.4754V64.2039V75.5427V77.5427H185.572H232.657V100.245ZM51.0854 60.854H25.2125L51.0854 30.2513V60.854ZM133.91 104C147.027 104 157.566 97.9958 164.773 88.587C171.948 79.2185 175.8 66.5191 175.8 53C175.8 39.5467 171.948 26.8486 164.774 17.4651C157.57 8.04162 147.031 2 133.91 2C120.852 2 110.348 8.04491 103.162 17.467C96.0069 26.8497 92.1546 39.5467 92.1546 53C92.1546 66.5191 96.0061 79.2174 103.163 88.5851C110.351 97.9925 120.856 104 133.91 104ZM133.91 86.0964C127.131 86.0964 121.48 82.5603 117.445 76.6134C113.388 70.633 111.029 62.2722 111.029 53C111.029 43.7278 113.388 35.367 117.445 29.3866C121.48 23.4397 127.131 19.9036 133.91 19.9036C140.763 19.9036 146.447 23.446 150.495 29.3896C154.567 35.3685 156.926 43.7277 156.926 53C156.926 62.2723 154.567 70.6315 150.495 76.6104C146.447 82.554 140.763 86.0964 133.91 86.0964ZM232.657 60.854H206.784L232.657 30.2513V60.854Z"
                stroke="#C53720"
                stroke-width="4"
              />
            </svg>
          </div>
          <h1 className={style.notfont_wrapper_svg_h1}>
            СТРАНИЦА <br /> НЕ НАЙДЕНА
          </h1>
          <p className={style.notfont_wrapper_svg_p}>
            Извините, но такой страницы нет на нашем сайте. Возможно вы ввели
            неправильный адрес.
          </p>
          <Notbutton />
          <div className={style.botp}></div>
        </div>
      </div>
    </>
  );
}
